// Generated by https://quicktype.io
export interface GetContactsQuery {
  Result: Contact[];
  Count: number;
}

export interface GetGroupContactsQuery {
  Result: Contact[];
  Count: number;
}

export interface Contact {
  Id: number;
  FirstName: string;
  LastName: string;
  Email: string;
  CompanyName: string;
  Type: number;
  Origin: number;
  EmailValidation: EmailValidation;
}

export enum ContactOrigin {
  Setvi = 0,
  SetviContact = 1,
  Salesforce = 2
}

export interface EmailValidation {
  VerificationStatusId: number;
  VerificationStatusDescription: VerificationStatusDescription;
  LastVerifiedDate: string;
}

export enum VerificationStatusDescription {
  Unchecked = 'Unchecked'
}

export interface GetGroupsQuery {
  Result: Group[];
  Count: number;
}

export interface Group {
  Id: number;
  Name: string;
  Description: string;
  ContactCount: number;
  CreatedDate: string;
  LastChangedAt: string;
  Contacts: Contact[];
}

export interface GetRecentContactsQuery {
  Result: Contact[];
  Count: number;
}

export interface GetSalesforceStatesQuery {
  Status: string;
  Data: State[];
  Error: null;
}

export interface State {
  State: string;
  StateCode: string;
  Country: string;
  CountryCode: string;
}

export interface GetSalesforceCitiesQuery {
  Status: string;
  Data: City[];
  Error: null;
}

export interface City {
  City: string;
  State: string;
  StateCode: string;
  Country: string;
  CountryCode: string;
}

export interface GetSalesforceAccountsQuery {
  Status: string;
  Data: Account[];
  Error: null;
}

export interface Account {
  Id: string;
  Name: string;
}

export interface GetSalesforceContactsQuery {
  Status: string;
  Data: CRMContact[];
  Error: null;
}

export interface CRMContact {
  AccountId: string;
  SalesforceId: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
  Title: string;
}
