import { Box, Paper, Popper, makeStyles } from '@material-ui/core';

import { Recipient } from '@setvi/shared/interfaces';

import { Contact as ContactInterface } from 'Services/Query/Contacts/Types';
import { EmailDimensions } from 'providersV2/compose-email';

import { Contact } from './contact';
import { SuggestedContactsLoader } from './loader';

interface SuggestedContactsProps {
  anchorEl: HTMLElement | null;
  selectedContacts: Recipient[];
  loading: boolean;
  suggestedContacts: ContactInterface[];
  onContactClick: (contact: ContactInterface) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: EmailDimensions.MAX_WIDTH_EXPANDED - 50,
    zIndex: 9
  }
}));

export const SuggestedContacts = ({
  anchorEl,
  loading,
  selectedContacts,
  suggestedContacts,
  onContactClick
}: SuggestedContactsProps) => {
  const classes = useStyles();

  return (
    <Popper
      disablePortal
      open={!!anchorEl}
      anchorEl={anchorEl}
      className={classes.root}
      placement="bottom-start">
      {suggestedContacts?.length > 0 && (
        <Paper elevation={3}>
          <Box>
            {loading && <SuggestedContactsLoader />}

            {!loading &&
              suggestedContacts.map(contact => (
                <Box
                  key={contact.Email}
                  onClick={() => onContactClick(contact)}>
                  <Contact
                    contact={contact}
                    selected={
                      !!selectedContacts.find(
                        selectedContact =>
                          selectedContact.Email === contact.Email
                      )
                    }
                  />
                </Box>
              ))}
          </Box>
        </Paper>
      )}
    </Popper>
  );
};
