import { Box, makeStyles, Checkbox } from '@material-ui/core';
import { SAvatar, SText } from '@setvi/shared/components';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import clsx from 'clsx';
import { memo } from 'react';
import { distances } from '../../../../styles';

const useContactStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    padding: distances.small,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    alignItems: 'center'
  },
  avatar: {
    marginRight: distances.small
  },
  details: { flexGrow: 1 },
  primaryText: {
    fontSize: 16
  },
  secondaryText: {
    fontSize: 12,
    color: '#92929D'
  },
  bold: {
    fontWeight: 500,
    color: '#44444F'
  }
}));

export type TContact = {
  Id: string | number;
  Email: string;
  FirstName?: string;
  LastName?: string;
  Title?: string;
  CompanyName?: string;
  Type?: number;
  CRMWhoId?: string;
  CRMWhatId?: string;
};

export interface ContactProps {
  contact: TContact;
  checked?: boolean;
  onClick?: (contact: TContact) => void;
}

export const Contact = memo(({ contact, checked, onClick }: ContactProps) => {
  const styles = useContactStyles();

  return (
    <Box className={styles.container}>
      <SAvatar
        className={styles.avatar}
        value={
          contact.FirstName && contact.LastName
            ? `${contact.FirstName.charAt(0)}${contact.LastName.charAt(0)}`
            : `${contact.Email?.slice(0, 2)}`
        }
      />
      <Box className={styles.details}>
        {(contact.FirstName || contact.LastName) && (
          <SText
            title={`${contact.FirstName ? contact.FirstName : ''} ${
              contact.LastName ? contact.LastName : ''
            }`}
          />
        )}
        {contact.CompanyName && (
          <SText className={styles.secondaryText} title={contact.CompanyName} />
        )}
        {contact.Title && (
          <SText className={styles.secondaryText} title={contact.Title} />
        )}
        <SText
          className={clsx({
            [styles.secondaryText]: contact.FirstName || contact.LastName,
            [styles.bold]: contact.FirstName || contact.LastName
          })}
          title={contact.Email}
        />
      </Box>
      <Box>
        <Checkbox
          onChange={() => onClick?.(contact)}
          checked={checked}
          color="primary"
          icon={<CheckBoxOutlineBlankIcon color="primary" />}
          checkedIcon={<CheckBoxIcon />}
        />
      </Box>
    </Box>
  );
});
