import { useEffect, useRef } from 'react';
import {
  Box,
  Divider,
  makeStyles,
  Typography,
  withStyles
} from '@material-ui/core';
import { Form, Formik } from 'formik';

import { SFormikEditor } from '@setvi/shared/components';
import { cleanHtml } from '@setvi/shared/utils';

import { EmailFormProps } from 'providersV2/compose-email/interfaces';
import { EmailDimensions } from 'providersV2/compose-email';
import { Contact } from 'Services/Query/Contacts/Types';
import { useCustomErrors } from 'Hooks/useCustomErrors';

import { ErrorIcon } from '@setvi/shared/components/sicons';
import { SuggestedContacts } from '../suggested-contacts';
import { composeBodyValidation, isValidEmail, validationSchema } from './utils';
import { RecipientField, SubjectField } from '../fields';
import { useEmailForm } from './hook';
import Footer from '../footer';

export const useStyles = makeStyles(({ palette }) => ({
  editor: {
    '& .tox-tinymce': {
      borderRadius: 0,
      border: 'none',
      borderTop: `1px solid ${palette.grey[300]}`
    }
  },
  errorIcon: {
    marginRight: '.5rem',
    width: '1.2rem',
    height: '1.2rem'
  }
}));

export const EmailExceededErrorMessage = withStyles({
  root: {
    color: '#FC5A5A',
    fontSize: '.8rem',
    marginBottom: '.5rem',
    display: 'flex',
    alignItems: 'center'
  }
})(Typography);

const EmailForm = ({
  links,
  values,
  loading,
  drawerType,
  initLinkOptions,
  initProductsOptions,

  onSubmit,
  setValues,
  toggleDrawer,
  setEditorNode,
  closeComposeEmail
}: EmailFormProps) => {
  const classes = useStyles();
  const ref = useRef(null);
  const {
    chips,
    anchorEl,
    suggestedContacts,
    recipientInputValue,
    isSuggestedContactsOpened,
    isSuggestedContactsLoading,

    onChipAdd,
    setAnchorEl,
    onChipDelete,
    setRecipientInputValue,
    onSuggestedContactSelect
  } = useEmailForm({
    values,
    setValues,
    closeComposeEmail
  });
  const emailToLimit = 250;
  const isEmailsLimitExceeded = values.sendTo.length > emailToLimit;

  const { customErrors, customValidate } = useCustomErrors({
    schemas: [
      {
        name: 'body',
        schema: composeBodyValidation
      }
    ],
    values: {
      body: cleanHtml(values.body)
    }
  });

  useEffect(() => {
    if (ref) ref.current.click();
  }, [ref]);

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={() => onSubmit()}>
      <Form
        onKeyDown={e => {
          e.key === 'Enter' && e.preventDefault();
        }}>
        <Box overflow="hidden">
          <Box
            maxHeight={EmailDimensions.MAX_HEIGHT_EXPANDED - 110}
            overflow="auto">
            <Box px={2}>
              <div ref={ref} onClick={e => setAnchorEl(e.currentTarget)}>
                <RecipientField
                  label="To"
                  name="sendTo"
                  chips={chips}
                  fieldValue={values.sendTo}
                  onChipAdd={onChipAdd}
                  onChipDelete={onChipDelete}
                  handleDrawer={toggleDrawer}
                  onClickAway={() => {
                    setAnchorEl(null);
                    setRecipientInputValue('');
                  }}
                  onInputChange={value => setRecipientInputValue(value)}
                  blurBehavior={
                    !isSuggestedContactsOpened ||
                    (isSuggestedContactsOpened &&
                      isValidEmail(recipientInputValue))
                      ? 'add'
                      : 'clear'
                  }
                />
                {isEmailsLimitExceeded && (
                  <EmailExceededErrorMessage>
                    <ErrorIcon className={classes.errorIcon} />
                    Max. {emailToLimit} recipients per email. Please create a
                    Campaign for more people.
                  </EmailExceededErrorMessage>
                )}
              </div>

              {isSuggestedContactsOpened && (
                <SuggestedContacts
                  anchorEl={anchorEl}
                  selectedContacts={values.sendTo}
                  loading={isSuggestedContactsLoading}
                  suggestedContacts={suggestedContacts}
                  onContactClick={(contact: Contact) => {
                    onSuggestedContactSelect(contact);
                  }}
                />
              )}

              <Divider />

              <SubjectField
                label="Subject"
                name="subject"
                boldText
                onChange={(v: string) =>
                  setValues((prevVals: any) => ({
                    ...prevVals,
                    subject: v
                  }))
                }
              />
            </Box>

            <SFormikEditor
              name="body"
              height={380}
              disableMargin
              content={values.body}
              onEditorClick={() => {
                setAnchorEl(null);
                setRecipientInputValue('');
              }}
              id="compose-email-editor"
              setups={[initLinkOptions, initProductsOptions]}
              customClass={classes.editor}
              customErrors={customErrors}
              setEditorNode={setEditorNode}
              handleChange={(value: string, text: string) => {
                setValues((prevVals: any) => ({
                  ...prevVals,
                  body: value,
                  textBody: text || ''
                }));

                customValidate({
                  schema: composeBodyValidation,
                  value: cleanHtml(value),
                  name: 'body'
                });
              }}
            />
          </Box>

          <Divider />
          <Footer
            handleDrawerOpen={toggleDrawer}
            drawerType={drawerType}
            loading={loading}
            isMailSendingAllowed={isEmailsLimitExceeded}
            links={links.length > 0}
          />
        </Box>
      </Form>
    </Formik>
  );
};

export default EmailForm;
